<template>
  <a-modal
    :title="title"
    :visible="open"
    :maskClosable="false"
    :keyboard="false"
    @cancel="importExcelHandleCancel"
    @ok="importExcelHandleChange"
  >
    <a-spin tip="上传中..." :spinning="uploading">
      <a-card title="剧集导入" style="width: 100%">
        <a-upload-dragger
          name="file"
          accept=".mp4"
          :multiple="true"
          :show-upload-list="false"
          list-type="picture"
          :customRequest="handleChange"
          @change="changeFile"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
        </a-upload-dragger>
        <div style="margin-top: 10px;margin-bottom: 10px">
          <a-card>
            <a-row type="flex">
              <a-statistic title="总计" :value="fileList.length" />
              <a-statistic
                title="正在上传"
                :value="computedUploadVideoing"
                :style="{
            margin: '0 32px',
          }"
                :value-style="{ color: '#cf1322' }"
              />
            </a-row>
          </a-card>


        </div>
        <div style="margin-bottom: 5px" v-for="(item,index) in fileList" :key="index" >
          <div style="width: 100%;display: flex;justify-content: space-between">
            <div><a-icon type="loading" v-if="item.percent != 100" style="margin-right: 10px"  /> <a-icon type="link" v-if="item.percent == 100" style="margin-right: 10px"  />{{item.name}}</div>
            <a-icon type="delete" @click.stop="clickDelete(index,1)" v-if="item.percent == 100" />
          </div>
          <a-progress style="height: 2px" v-if="item.percent != 100" :percent="item.percent" :show-info="false" />
        </div>
      </a-card>
      <a-card title="中文字幕"  style="width: 100%;margin-top: 10px">
        <a-upload-dragger
          name="file"
          accept=".srt,.vtt"
          :multiple="true"
          :show-upload-list="false"
          :customRequest="handleChangeZh"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
        </a-upload-dragger>
        <div style="margin-top: 10px;margin-bottom: 10px">
          <a-card>
            <a-row type="flex">
              <a-statistic title="总计" :value="fileZhList.length" />
              <a-statistic
                title="正在上传"
                :value="computedUploadZhing"
                :style="{
            margin: '0 32px',
          }"
                :value-style="{ color: '#cf1322' }"
              />
            </a-row>
          </a-card>


        </div>
        <div style="margin-bottom: 5px" v-for="(item,index) in fileZhList" :key="index" >
          <div style="width: 100%;display: flex;justify-content: space-between">
            <div><a-icon type="loading" v-if="item.percent != 100" style="margin-right: 10px"  /> <a-icon type="link" v-if="item.percent == 100" style="margin-right: 10px"  />{{item.name}}</div>
            <a-icon type="delete" @click.stop="clickDelete(index,2)" v-if="item.percent == 100"  />

          </div>
          <a-progress style="height: 2px" v-if="item.percent != 100" :percent="item.percent" :show-info="false" />
        </div>
      </a-card>

      <a-card title="英文字幕" style="width: 100%;margin-top: 10px">
        <a-upload-dragger
          name="file"
          accept=".srt,.vtt,.mov"
          :multiple="true"
          :show-upload-list="false"
          :customRequest="handleChangeEn"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
        </a-upload-dragger>
        <div style="margin-top: 10px;margin-bottom: 10px">
          <a-card>
            <a-row type="flex">
              <a-statistic title="总计" :value="fileEnList.length" />
              <a-statistic
                title="正在上传"
                :value="computedUploadEning"
                :style="{
            margin: '0 32px',
          }"
                :value-style="{ color: '#cf1322' }"
              />
            </a-row>
          </a-card>


        </div>
        <div style="margin-bottom: 5px" v-for="(item,index) in fileEnList" :key="index" >
          <div style="width: 100%;display: flex;justify-content: space-between">
            <div><a-icon type="loading" v-if="item.percent != 100" style="margin-right: 10px"  /> <a-icon type="link" v-if="item.percent == 100" style="margin-right: 10px"  />{{item.name}}</div>
            <a-icon type="delete" @click.stop="clickDelete(index,3)"  v-if="item.percent == 100" />

          </div>
          <a-progress style="height: 2px" v-if="item.percent != 100" :percent="item.percent" :show-info="false" />

        </div>
      </a-card>

      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </a-spin>
  </a-modal>
</template>

<script>

import { importData } from '@/api/video/gather'
import UploadFileToVOD from "@/api/vod";
import UploadFileToVODFile from "@/api/UploadFileToVOD";
import axios from 'axios'
export default {
  name: 'ImportExcel',
  props: {
  },
  components: {
  },
  data () {

    return {
      title: '导入',
      open: false,
      uploadStatus: '',
      previewImage: '',
      fileList: [],
      fileZhList:[],
      fileEnList:[],
      // 是否禁用上传
      uploading: false,
      previewVisible: false,
      updateSupport: 0,
      id:''
    }

  },

  filters: {
  },
  created () {
  },
  computed: {
    computedUploadVideoing() {
      if(this.fileList.length >0) {
        return this.fileList.filter(i => {
          return i.percent != 100 || i.url === ''
        }).length;
      } else {
        return 0;
      }
    },
    computedUploadZhing() {
      if(this.fileZhList.length >0) {
        return this.fileZhList.filter(i => {
          return i.percent != 100 || i.url === ''
        }).length;
      } else {
        return 0;
      }
    },
    computedUploadEning() {
      if(this.fileEnList.length >0) {
        return this.fileEnList.filter(i => {
          return i.percent != 100 || i.url === ''
        }).length;
      } else {
        return 0;
      }
    },
  },
  watch: {
  },
  methods: {
    clickDelete(index,num) {
      let that = this;
      this.$confirm({
        content:'是否确认删除呢?',
        centered:true,
        onOk() {
          if(num == 1) {
            that.fileList.splice(index,1)
          }

          if(num == 2) {
            that.fileZhList.splice(index,1)
          }

          if(num == 3) {
            that.fileEnList.splice(index,1)
          }
        },
        onCancel() {
          // that.open = false
          // that.fileList = [];
          // that.fileEnList = [];
          // that.fileZhList = [];
        }
      })

    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },

    changeFile(info) {
      console.log("11111111111111111111111")
      console.log(info)
      // this.fileList.push({
      //   uid:info.file.uid,
      //   name:info.file.name,
      //   status: 'done',
      //   url:'',
      //   percent:0
      // })
    },
    handleChange(info) {
      this.fileList.push({
        uid:info.file.uid,
        name:info.file.name,
        status: 'done',
        url:'',
        percent:0
      })
      console.log(this.fileList)
      let that = this
      // that.form.videoUrl = ""
      let findIndex= that.fileList.findIndex(i => i.uid == info.file.uid);
      console.log('+_------' +findIndex)
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          if(findIndex != -1) {
            that.fileList[findIndex].url = fileName[0];
            that.$set( that.fileList[findIndex],'url',fileName[0]);
          }
        },
        vProgress:function (val) {
          if(findIndex != -1) {
            that.fileList[findIndex].percent = val * 100;
            that.$set(that.fileList[findIndex],'percent',val* 100);
          }
        }
      });
    },

    handleChangeZh(info) {
      let that = this;
      this.fileZhList.push({
        uid:info.file.uid,
        name:info.file.name,
        status: 'done',
        url:'',
        percent:0
      })
      let findIndex= that.fileZhList.findIndex(i => i.uid == info.file.uid);

      let FormDatas = new FormData();
      FormDatas.append('file', info.file)
      //      //        url: "https://web.frameflash.com/admin-api/common/local/file/uploadCos", // 接口地址

      axios({
        method: 'post',
        url: "http://122.114.5.60:9086/admin-api/common/local/file/uploadCos", // 接口地址
        data: FormDatas,
        // 请求配置按需配置即可
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data;boundary=' + new Date().getTime()
        },
        // 上传进度
        onUploadProgress: (progressEvent) => {
          let num = progressEvent.loaded / progressEvent.total * 100 | 0 // 计算进度
          console.log(num)
          if(findIndex != -1) {
            that.fileZhList[findIndex].percent = num;
            that.$set(that.fileZhList[findIndex],'percent',num);
          }
        }
      }).then((res) => {
        console.log('res---------')
        console.log(res)
        console.log(findIndex)
        if(findIndex != -1) {
          that.fileZhList[findIndex].url = res.data.data.mediaUrl;
          that.$set( that.fileZhList[findIndex],'url',res.data.data.mediaUrl);
        }
      }).finally(() => {
      })
      console.log(info)

    },

    handleChangeEn(info) {
      let that = this;

      this.fileEnList.push({
        uid:info.file.uid,
        name:info.file.name,
        status: 'done',
        url:'',
        percent:0
      })

      let findIndex= that.fileEnList.findIndex(i => i.uid == info.file.uid);

      let FormDatas = new FormData();
      FormDatas.append('file', info.file)
      //        url: "https://web.frameflash.com/admin-api/common/local/file/uploadCos", // 接口地址

      axios({
        method: 'post',
        url: "http://122.114.5.60:9086/admin-api/common/local/file/uploadCos", // 接口地址
        data: FormDatas,
        // 请求配置按需配置即可
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data;boundary=' + new Date().getTime()
        },
        // 上传进度
        onUploadProgress: (progressEvent) => {
          let num = progressEvent.loaded / progressEvent.total * 100 | 0 // 计算进度
          console.log(num)
          if(findIndex != -1) {
            that.fileEnList[findIndex].percent = num;
            that.$set(that.fileEnList[findIndex],'percent',num);
          }
        }
      }).then((res) => {
        console.log('res---------')
        console.log(res)
        console.log(findIndex)
        if(findIndex != -1) {
          that.fileEnList[findIndex].url = res.data.data.mediaUrl;
          that.$set( that.fileEnList[findIndex],'url',res.data.data.mediaUrl);
        }
      }).finally(() => {
      })
      console.log(info)

    },
    /** 导入excel窗体关闭 */
    importExcelHandleCancel (e) {
      let that = this;
      if(this.fileList.length > 0 || this.fileEnList.length > 0 || this.fileZhList.length > 0) {
        this.$confirm({
          content:'您上传的短剧/字幕文件尚未保存是否关闭?',
          centered:true,
          onOk() {
            that.open = false
            that.fileList = [];
            that.fileEnList = [];
            that.fileZhList = [];
          },
          onCancel() {
            // that.open = false
            // that.fileList = [];
            // that.fileEnList = [];
            // that.fileZhList = [];
          }
        })
      } else {
        this.open = false
        this.fileList = [];
        this.fileEnList = [];
        this.fileZhList = [];
      }

      // // 关闭后刷新列表
      // this.$emit('ok')
    },
    /** 下载模板操作 */
    importTemplate () {
      this.download('/video/video-gather/importTemplate', {
        ...this.queryParams
      }, `gather_template_${new Date().getTime()}.xlsx`)
    },
    /** 导入excel窗体开启 */
    importExcelHandleOpen (e) {
      this.id = e;
      this.open = true
    },
    beforeUpload (file) {
      this.fileList = [file]
      return false
    },
    /** 导入excel */
    importExcelHandleChange () {
     if(this.fileList.length >0) {
        if(this.computedUploadVideoing != 0) {
          this.$message.error("剧集文件还未上传完毕,请耐心等待")
          return;
        }
     }

      if(this.fileZhList.length >0) {
        if(this.computedUploadZhing != 0) {
          this.$message.error("中文字幕文件还未上传完毕,请耐心等待")
          return;
        }
      }

      if(this.fileEnList.length >0) {
        if(this.computedUploadEning != 0) {
          this.$message.error("英文字幕文件还未上传完毕,请耐心等待")
          return;
        }
      }


      this.uploading = true
      // const { fileList } = this
      // const formData = new FormData()
      // formData.append('file', fileList[0])
      // formData.append('updateSupport', this.updateSupport)

      let arrList =this.fileList.map(i => ({
        serialNumber:/-/.test(i.name) ? i.name.split(".")[0].split("-")[1] : i.name.split(".")[0],
        videoUrl:i.url,
        videoId:this.id
      }))
     let enList = this.fileEnList.map(i => ({
        serialNumber:/-/.test(i.name) ?i.name.split(".")[0].split("-")[1]: i.name.split(".")[0],
         subtitleEn:i.url,
       videoId:this.id,
      }));
      let zhList = this.fileZhList.map(i => ({
        serialNumber:/-/.test(i.name) ? i.name.split(".")[0].split("-")[1]: i.name.split(".")[0],
        subtitleCn:i.url,
        videoId:this.id,
      }))
      let newArrData = {
        arrList:arrList,
        enList:enList,
        zhList:zhList
      }
      importData(JSON.stringify(newArrData)).then(response => {
        if(response.code == 1 || response.code == 20001) {
          this.fileList = [];
          this.fileEnList = [];
          this.fileZhList = [];
          this.$message.success(response.message)
          this.open = false
          this.$emit('ok')
        }else {
          this.$message.error(response.message)
          return
        }

      }).finally(() => {
        this.uploading = false
        this.$emit('ok')
      })
    },
    handleCheckedUpdateSupport () {
      this.updateSupport = this.updateSupport === 0 ? 1 : 0
    },
    handleRemove () {
      this.fileList = []
      this.uploading = false
    },
    handleRemoveZh () {

      this.fileZhList = [];
      this.uploading = false
    },
    handleRemoveEn () {
      this.fileEnList = [];
      this.fileList = []
      this.uploading = false
    },
  }
}
</script>
