<template>
  <a-modal width="20%" :label-col="4" :wrapper-col="14" :footer="null" :visible="open" @cancel="onClose">

    <video :src="this.dataUrl" width="100%" height="150" controls></video>
  </a-modal>
</template>

<script>
import { getGather, addGather, updateGather } from '@/api/video/gather'
import { listVideo } from '@/api/video/video'
import TagSelectOption from "@/components/TagSelect/TagSelectOption";
import UploadFileToVOD from "@/api/vod.js"
export default {
  name: 'VideoUtil',
  props: {
  },
  components: {
    TagSelectOption
  },
  data () {
    return {
      dataUrl:"",
      submitLoading: false,
      formTitle: '',
      progress: 0,
      uploadLoading:false,
      // 表单参数
      form: {
        id: null,

        type: null,

        duration: null,

        videoUrl: null,

        videoId: null,

        serialNumber: null,

        createTime: null,

        remark: null,
        subtitleCn: null,
        subtitleEn: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      fileList:[],
      fileListEn:[],
      rules: {
        type: [
          { required: true, message: '购买类型不能为空', trigger: 'blur' }
        ],
        duration: [
          { required: true, message: '短剧时长不能为空', trigger: 'blur' }
        ],
        videoUrl: [
          { required: true, message: '视频不能为空', trigger: 'blur' }
        ],
        videoId: [
          { required: true, message: '短剧名称不能为空', trigger: 'blur' }
        ],
        serialNumber: [
          { required: true, message: '当前集数不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getGather();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    beforeUploadVideo:function (file) {
      debugger
      var fileType = file.type;
      if (fileType.indexOf('video') < 0) {
        this.$message.warning('请上传视频');
        this.form.videoUrl = '';
        return false
      }

      //TODO 驗證文件大小
    },

    getUrl(value) {
      this.open=true
      this.dataUrl=value
    },
    handleChangeCn(info) {
      var fileType = info.file.name;
      if (fileType.indexOf('vtt') < 0) {
        this.$message.warning('请上传vtt文件');
        this.form.subtitleCn=''
      }

      let that = this
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          debugger
          console.log(fileName)
          that.form.subtitleCn = fileName[0];//文件名
        }
      });
      // if (info.file.status === 'done') {
      //   console.log(info.file.response.data.mediaUrl);
      //   this.form.subtitleCn = info.file.response.data.mediaUrl;
      //   // Get this url from response in real world.
      //   // getBase64(info.file.originFileObj, imageUrl => {
      //   //   this.imageUrl = imageUrl;
      //   //   this.uploadLoading = false;
      //   // });
      // } else {
      //   this.form.subtitleCn = "";
      // }
      // if(info.file.status === 'removed') {
      //   this.fileList = []
      // } else {
      //   let fileList = [...info.fileList];
      //   fileList = fileList.slice(-1);
      //   fileList[0].name = '文件'
      //   fileList = fileList.map(file => {
      //     if (file.response) {
      //       file.url = file.response.url;
      //     }
      //     return file;
      //   });
      //   this.fileList = fileList;
      //   console.log(this.fileList);
      //   if (info.file.status === 'done') {
      //     console.log(that.form.subtitleCn);
      //     this.form.subtitleCn = that.form.subtitleCn;
      //     // Get this url from response in real world.
      //     // getBase64(info.file.originFileObj, imageUrl => {
      //     //   this.imageUrl = imageUrl;
      //     //   this.uploadLoading = false;
      //     // });
      //   } else {
      //     this.form.subtitleCn = "";
      //   }
      // }

    },
    handleChangeEn(info) {
      var fileType = info.file.name;
      if (fileType.indexOf('vtt') < 0) {
        this.$message.warning('请上传vtt文件');
        this.form.subtitleEn=''
      }
      let that = this
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          that.form.subtitleEn = fileName[0];//文件名
        }
      });
      // if (info.file.status === 'done') {
      //   console.log(info.file.response.data.mediaUrl);
      //   this.form.subtitleEn = info.file.response.data.mediaUrl;
      //   // Get this url from response in real world.
      //   // getBase64(info.file.originFileObj, imageUrl => {
      //   //   this.imageUrl = imageUrl;
      //   //   this.uploadLoading = false;
      //   // });
      // } else {
      //   this.form.subtitleEn = "";
      // }
      return;
      if(info.file.status === 'removed') {
        this.fileListEn = []
      } else {
        let fileList = [...info.fileList];
        fileList[0].name = '文件'
        fileList = fileList.slice(-1);
        fileList = fileList.map(file => {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        this.fileListEn = fileList;
        if (info.file.status === 'done') {
          console.log(info.file.response.data.mediaUrl);
          this.form.subtitleEn = info.file.response.data.mediaUrl;
          // Get this url from response in real world.
          // getBase64(info.file.originFileObj, imageUrl => {
          //   this.imageUrl = imageUrl;
          //   this.uploadLoading = false;
          // });
        } else {
          this.form.subtitleEn = "";
        }
      }


    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true;
        return;
      }
      let that = this
      that.form.videoUrl = ""
      new UploadFileToVOD([info.file], {
        success: function (fileName) {

          that.form.videoUrl = fileName[0];//文件名
          that.uploadLoading = false;
        }
      });
      that.uploadLoading = false;



    },
    handleUpload(info) { //上传主图(一张图)
      console.log(info)
      let that = this;
      this.form.videoUrl = "";
      this.uploadLoading = true;
      // ossUpload(info.file)
      //  , function (fileName) {
      //   console.log('--------------------------------')
      //   console.log(fileName)
      //   console.log('--------------------------------')
      //   that.uploadLoading = false;
      //   that.form.coverUrl = fileName;
      // }
    },
    getGather() {
      listVideo().then(res => {
        this.listAll = res.data;
      })
    },
    onClose () {
      this.open = false
      this.uploadLoading = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        isGoldBuy: null,
        duration: null,
        videoUrl: null,
        videoId: null,
        serialNumber: null,
        createTime: null,
        remark: null,
        subtitleCn: null,
        subtitleEn: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGather({"id":id}).then(response => {
        this.form = response.data;
        if(response.data.subtitleCn) {
          this.fileList=[{
            uid: '-1',
            name: '文件',
            status: 'done',
            url: response.data.subtitleCn,
          }]
        } else {
          this.fileList=[]
        }

        if(response.data.subtitleEn) {
          this.fileListEn=[{
            uid: '1',
            name: '文件',
            status: 'done',
            url: response.data.subtitleEn,
          }]
        } else {
          this.fileListEn=[]
        }

        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGather(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGather(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
