
import {getTenCosConfig} from '@/api/system/upload.js'

export default class UploadFileToVOD {
  constructor(files, json) {
    if (!Array.isArray(files)) {
      throw new Error('Class UploadFileToOSS parameter must be an array');
    }
    console.log('files, json', files, json)
    this.data = [];
    this.fn = json.success;
    this.complete = json.finally;
    this.vProgress = json.vProgress;
    this.files = files;
    this.fileLen = this.files.length;
    this.curIndex = 0;
    // notification.info({ message: '上传中' })
    this.upload();
  }




     upload() {
    console.log(getTenCosConfig())
       const tcVod = new TcVod.default({
         getSignature: getTenCosConfig, // 签名数据
         dynamicAccelerate: true, // 开启动态加速
       })

       const uploader = tcVod.upload({
         mediaFile: this.files[0], // 媒体文件（视频或音频或图片），类型为 File
           // fileParallelLimit:10,
           // chunkParallelLimit:10,
           // dynamicAccelerate:true
       })
         let that = this;
         uploader.on('media_progress', function(info) {
             // this.vProgress = info.percent
             // console.log(info)
             if(that.vProgress) that.vProgress(info.percent)
         })

         uploader.done().then(function (doneResult) {
             that.data.push(doneResult.video.url)
             // that.complete(that.data);
             if(that.fn) that.fn(that.data)
             // deal with doneResult
         }).catch(function (err) {
             // deal with error
         })


     }
}